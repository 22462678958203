import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const Aside = makeShortcode("Aside");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Create interactive gaming assets for your virtual world.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB5DC80sf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAQISIQMR/9oACAEBAAEFAnzxxKb6WZeR/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAECAQE/AVf/xAAYEAACAwAAAAAAAAAAAAAAAAAAIQEgMf/aAAgBAQAGPwJEqmn/xAAcEAEAAwACAwAAAAAAAAAAAAABABFBITFRobH/2gAIAQEAAT8hDDr3ONgyqihH2DNlO5Uu8J//2gAMAwEAAgADAAAAEP8AP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxChP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxCFP//EABoQAQEBAQEBAQAAAAAAAAAAAAERIQAxQVH/2gAIAQEAAT8QQlJAe/HvNiToiH7OvKAwiKfveEjJjwSDyF+HMGoJmXv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9cde795de6d45289f4ebc4ca9f6083a8/5f1c3/chess.webp 288w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/c4eb6/chess.webp 576w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/c2ad5/chess.webp 1152w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/8de58/chess.webp 1728w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/a2d2e/chess.webp 2304w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/537f1/chess.webp 5472w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9cde795de6d45289f4ebc4ca9f6083a8/58ca1/chess.jpg 288w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/7fee0/chess.jpg 576w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/914fe/chess.jpg 1152w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/1889a/chess.jpg 1728w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/a9b74/chess.jpg 2304w", "/static/9cde795de6d45289f4ebc4ca9f6083a8/fc1fc/chess.jpg 5472w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9cde795de6d45289f4ebc4ca9f6083a8/914fe/chess.jpg",
              "alt": "Game assets - Magnus Code - Malaysia",
              "title": "Game assets - Magnus Code - Malaysia",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
        <Caption fullWidth mdxType="Caption">Bring your games to life.</Caption>
        <Row mdxType="Row">
  Magnus Code game asset specialized in creating game specific components.
Our certified professionals can create various style and texture for game models and environments
        </Row>
        <Row mdxType="Row">
  Our in-house gaming solutions can also provide interactive gaming solutions such as creating dynamic games for your virtual summit or online conference. 
        </Row>
        <Row mdxType="Row">
  With Magnus Code, you can ensure that your organization are always one step ahead in digitalizing your organization.
        </Row>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" mdxType="Aside">
          <p><strong parentName="p">{`Game assets.`}</strong></p>
          <p>{`Make your conference and summits more interactive`}</p>
        </Aside>
      </Column>
    </Row>
    <Row className="resource-card-group mt-4 " mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Talk to our team" title="Contact Us" aspectRatio="2:1" actionIcon="arrowRight" href="/contact" color="light" className="blue-card" mdxType="ResourceCard"></ResourceCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      